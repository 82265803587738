import React, { Fragment } from "react";
import { Layout, SEO, ShopProductDetail } from "src/components";
import { Hero, Nav } from "src/components";
import { graphql } from "gatsby";
import { Typography, useTheme } from "@material-ui/core";
import { HeroTemplate } from "src/services/prismic/templates";

const ProductsPage = ({
  data: {
    shopifyProduct: product,
    prismicData: pageData,
    allShopifyProduct: metafieldValue,
    AllPopularproduct: PopularProducts
  }
}) => {
  return (
    <Fragment>
      <SEO
        title={product?.title}
        description={pageData.data?.description.text}
        path="/"
      />
      <Layout>
        {pageData?.data?.body?.map((slice, idx) => {
          switch (slice.__typename) {
            case "PrismicPageBodyHero":
              return <HeroTemplate data={slice} key={idx} />;
            case "PrismicPageBodyShopPageListing":
              return (
                <ShopProductDetail
                  productData={product}
                  MetaData={metafieldValue}
                  RelatedProducts={PopularProducts}
                  data={slice}
                  otherMeta={pageData?.data?.body}
                />
              );
            default:
              return null;
          }
        })}
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  query($id: String!) {
    prismicData: prismicPage(uid: { eq: "product" }) {
      data {
        title {
          text
        }
        description {
          text
        }
        body {
          ...PrismicHero
          ...ShopPageListing
          ...Shipping
          ...Returns
        }
      }
    }
    shopifyProduct(id: { eq: $id }) {
      id
      title
      descriptionHtml
      totalInventory
      tags
      storefrontId
      featuredImage {
        gatsbyImageData
        transformedSrc
      }
      images {
        id
        gatsbyImageData
      }
      priceRangeV2 {
        minVariantPrice {
          amount
        }
        maxVariantPrice {
          amount
        }
      }
      options {
        name
        values
        id
      }
      variants {
        title
        shopifyId
        price
        sku
        availableForSale
        inventoryQuantity
        storefrontId
        selectedOptions {
          name
          value
        }
      }
    }
    allShopifyProduct(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          metafields {
            id
            value
            key
          }
        }
      }
    }
    AllPopularproduct: allShopifyProduct(filter: { id: { ne: $id } }) {
      edges {
        node {
          id
          title
          description
          descriptionHtml
          totalInventory
          tags
          variants {
            id
            weight
            sku
            availableForSale
            inventoryQuantity
            selectedOptions {
              name
              value
            }
          }
          featuredImage {
            gatsbyImageData
            transformedSrc
          }
          images {
            id
            gatsbyImageData
          }
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
  }
`;
export default ProductsPage;
